import React, { useState } from "react";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem, ItemGroup as MenuItemGroup } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import { DeviceContainer, DeviceOpener, DeviceToggle, DeviceItem } from "./videoStyles";
// @ts-ignore
import CameraVideoFill from "@assets/icons/cameraVideoFill.svg";
// @ts-ignore
import CameraVideoFillOff from "@assets/icons/cameraVideoFillOff.svg";
// @ts-ignore
import MicFill from "@assets/icons/micFill.svg";
// @ts-ignore
import MicMuteFill from "@assets/icons/micMuteFill.svg";
// @ts-ignore
import CaretDown from "@assets/icons/caretDown.svg";
// @ts-ignore
import CaretUp from "@assets/icons/caretUp.svg";

interface IDevice {
  label: string;
  deviceId: string;
  kind: string;
}

interface IDeviceSelectorProps {
  onSelect: (device: any) => void;
  onToggle: () => void;
  devices: IDevice[];
  icon: "mic" | "camera";
  active: boolean;
  currentCameraDevice?: string;
  currentMicDevice?: string;
  currentSpeakerDevice?: string;
}

export const DeviceSelector = ({
  onToggle,
  onSelect,
  devices,
  icon,
  active,
  currentCameraDevice,
  currentMicDevice,
  currentSpeakerDevice,
}: IDeviceSelectorProps) => {
  const [show, setShow] = useState(false);

  const onSelectDevice = (option: any) => {
    const { key } = option;
    if (icon === "camera") {
      onSelect(key);
    } else {
      const regex = /^(audioinput|audiooutput) - (.+)$/;
      const match = key.match(regex);
      if (match) {
        const kind = match[1];
        const deviceId = match[2];
        onSelect({ kind, deviceId });
      }
    }
  }

  const onVisibleChange = (visible: boolean) => {
    setShow(visible);
  }

  const onToggleDevice = (e: any) => {
    e.stopPropagation();
    onToggle();
  }

  const cameraDeviceOptionList = (
    <Menu onSelect={onSelectDevice}>
      {devices.map(device => (
        <MenuItem key={device.deviceId}>
          <DeviceItem
            key={device.deviceId}
            selected={device.deviceId === currentCameraDevice}
          >
            {device.label}
          </DeviceItem>
        </MenuItem>
      ))}
    </Menu>
  );

  const audioDeviceOptionsList = (
    <Menu onSelect={onSelectDevice} itemIcon={<></>}>
      <MenuItemGroup title="Microphone">
        {devices
          .filter((device) => device.kind === "audioinput")
          .map(({deviceId, label, kind}) => (
            <MenuItem key={`${kind} - ${deviceId}`}>
              <DeviceItem
                key={deviceId}
                selected={deviceId === currentMicDevice}
              >
                {label}
              </DeviceItem>
            </MenuItem>
          ))
        }
      </MenuItemGroup>
      <MenuItemGroup title="Speaker">
        {devices
          .filter((device) => device.kind === "audiooutput")
          .map(({deviceId, label, kind}) => (
            <MenuItem key={`${kind} - ${deviceId}`}>
              <DeviceItem
                key={deviceId}
                selected={deviceId === currentSpeakerDevice}
              >
                {label}
              </DeviceItem>
            </MenuItem>
          ))
        }
      </MenuItemGroup>
    </Menu>
  );

  return icon === "camera" ? (
    <Dropdown
      trigger={['click']}
      overlay={devices.length > 0 ? cameraDeviceOptionList : <></>}
      onVisibleChange={onVisibleChange}
    >
      <DeviceContainer active={active}>
        <DeviceToggle
          data-type="DeviceToggle"
          onClick={(e) => onToggleDevice(e)}
          active={active}
        >
          {active ? <CameraVideoFill /> : <CameraVideoFillOff />}
        </DeviceToggle>
        {devices.length > 0 && (
          <DeviceOpener>
            {show ? <CaretDown /> : <CaretUp />}
          </DeviceOpener>
        )}
      </DeviceContainer>
    </Dropdown>
  ) : (
    <Dropdown
      trigger={['click']}
      overlay={devices.length > 0 ? audioDeviceOptionsList : <></>}
      onVisibleChange={onVisibleChange}
    >
      <DeviceContainer active={active}>
        <DeviceToggle
          data-type="DeviceToggle"
          onClick={(e) => onToggleDevice(e)}
          active={active}
        >
          {active ? <MicFill /> : <MicMuteFill />}
        </DeviceToggle>
        {devices.length > 0 && (
          <DeviceOpener>
            {show ? <CaretDown /> : <CaretUp />}
          </DeviceOpener>
        )}
      </DeviceContainer>
    </Dropdown>
  );
};
