import { styled } from "linaria/react";

export const CallSimulatorForm = styled.form`
  max-width: 600px;
  margin: 20px auto;
  width: 100%;
`;

export const CallSimulatorHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CallSimulatorSubmit = styled.button`
  background-color: rgb(0, 123, 255);
  border-radius: 4px;
  color: white;
  height: 38px;
  border: 0;
  margin-top: 25px;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;
