import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { useTrack } from "use-twilio-video";
import { BlurView, BlackVisibilityView } from "@components/styles";
import {
  ParticipantWrap,
  ParticipantTop,
  ParticipantBottom,
  Identity,
  ActionButton,
  ActionItem,
  VideoBox
} from "./videoStyles";
import { LanguageSelector } from "./LanguageSelector";
import { IDialectEntity } from "@utils";
import { connect } from "react-redux";
import { IRootState, ConveyApi } from "@utils";
import { formatPhoneNumber } from "@lib/utils/helpers";
// @ts-ignore
import MicMute from "@assets/icons/micMute.svg";
// @ts-ignore
import VideoOff from "@assets/icons/videoOff.svg";
// @ts-ignore
import ThreeDot from "@assets/icons/horizontalThreeDot.svg";
// @ts-ignore
import Avatar from "@assets/images/avatar-transparent.png";
// @ts-ignore
import EyeSlashSolid from "@assets/icons/eyeSlashSolid.svg";

interface IParticipantProps {
  participant: any;
  isLocalParticipant: boolean;
  localVideoTrack?: any;
  localAudioTrack?: any;
  identity: string;
  conversationId: string;
  roomId: string;
  defaultLanguage?: string;
  recipientFlow: boolean;
  onSelectLanguage?: (identity: string, selectedLanguage: IDialectEntity) => void;
  onSwitchCamera?: (identity: string) => void;
  onToggleAudio?: (identity: string, status: boolean) => void;
  onToggleVideo?: (identity: string, status: boolean) => void;
  onShareLocation?: (identity: string) => void;
  onRemovePartcipant?: (partcipantId: string) => void;
  showRemoteControl: boolean;
  dialects: IDialectEntity[];
  blur?: boolean;
  app: IRootState["app"];
  agency: IRootState["agency"];
  conversations: IRootState["conversations"];
  numberOfParticipants: number;
  minimized: boolean;
  vertical: boolean;
  enableVideo: boolean;
  enableAudio: boolean;
  defaultDialect?: string;
  isCameraOn?: boolean;
  isMicrophoneOn?: boolean;
  currentSpeaker?: string;
  visibility?: boolean;
}

const XParticipant: FunctionComponent<IParticipantProps> = ({
  participant,
  recipientFlow,
  identity,
  onSelectLanguage,
  onSwitchCamera,
  onToggleAudio,
  onToggleVideo,
  onShareLocation,
  onRemovePartcipant,
  showRemoteControl = false,
  dialects,
  conversationId,
  roomId,
  defaultDialect,
  blur: initialBlur,
  isCameraOn,
  isMicrophoneOn,
  vertical,
  app,
  agency,
  conversations,
  numberOfParticipants,
  minimized,
  isLocalParticipant,
  currentSpeaker,
  localAudioTrack,
  localVideoTrack,
  visibility = true
}) => {
  const currentConversation = conversations[conversationId];
  const recipient = currentConversation?.entity?.recipients?.find(
    (recipient) => recipient.label === participant.identity
  );
  const { videoTrack, audioTrack, audioOn, videoOn } = useTrack({
    participant
  });
  const [blur, setBlur] = useState(initialBlur);

  useEffect(() => {
    if (isLocalParticipant && !isCameraOn) {
      localVideoTrack?.disable && localVideoTrack?.disable();
    }
  }, [isCameraOn, isLocalParticipant, localVideoTrack]);

  useEffect(() => {
    if (isLocalParticipant && !isMicrophoneOn) {
      localAudioTrack?.disable && localAudioTrack?.disable();
    }
  }, [isMicrophoneOn, isLocalParticipant, localAudioTrack]);

  const toggleVideo = () => {
    onToggleVideo && onToggleVideo(identity, !videoOn);
  };

  const toggleAudio = () => {
    onToggleAudio && onToggleAudio(identity, !audioOn);
  };

  const removeParticipant = useCallback(async () => {
    await ConveyApi.deleteVideoRoomParticipant({ roomId, participantId: participant.sid });
  }, [participant]);

  // const sendLocationRequest = async () => {
  //   const requestResponse = await ConveyApi.requestLocation({
  //     conversationId,
  //     recipientId: recipient?.recipient_id,
  //     agencyId: app.agencyId,
  //     phoneNumber: recipient.number,
  //     message: agency?.settings?.locationRequestExplainMsg || ""
  //   }).catch(() => null);
  // }

  useEffect(() => {
    const container = document.getElementById(`video-room-participant-${participant.identity}`);
    const audioElements = container.getElementsByTagName("audio");
    if (audioElements && audioElements.length > 0) {
      const audioElement = audioElements.item(0);
      if (typeof audioElement.setSinkId === "function") {
        audioElement.setSinkId(currentSpeaker);
      }
    }
  }, [currentSpeaker]);

  useEffect(() => {
    if (isLocalParticipant) {
      console.log('[LocalParticipant]')
      const container = document.getElementById(`video-room-participant-${participant.identity}`);
      console.log('[LocalParticipant]: ', { container })

      if (container) {
        console.log('[LocalParticipant]: ', { localVideoTrack, localAudioTrack });
        if (localVideoTrack) {
          console.log('[LocalParticipant] attaching video track: ');
          const videoId = `video-room-participant-${participant.identity}-video`;
          const oldVideoEle = document.getElementById(videoId);
          if (oldVideoEle) {
            container.removeChild(oldVideoEle);
          }
          const newElement = localVideoTrack.attach();
          newElement?.setAttribute && newElement.setAttribute("id", videoId);
          container.appendChild(newElement);
        }
        if (localAudioTrack) {
          console.log('[LocalParticipant] attaching audio track: ');
          const audioId = `video-room-participant-${participant.identity}-audio`;
          const oldAudioEle = document.getElementById(audioId);
          if (oldAudioEle) {
            container.removeChild(oldAudioEle);
          }
          const newElement = localAudioTrack.attach();
          newElement?.setSinkId && newElement.setSinkId(currentSpeaker);
          newElement?.setAttribute && newElement.setAttribute("id", audioId);
          container.appendChild(newElement);
        }
      }
    }
  }, [localAudioTrack, localVideoTrack]);

  useEffect(() => {
    if (!isLocalParticipant) {
      const container = document.getElementById(`video-room-participant-${participant.identity}`);

      const trackPublished = async (publication, container) => {
        if (publication.isSubscribed) {
          console.log('[RemoteParticipant:trackPublished]: ', { publication, container });
          await attachTrack(publication.track, container);
        } else {
          publication.on("subscribed", async (track) => {
            console.log('[RemoteParticipant:trackPublished:subscribed]: ', { container, track });
            await attachTrack(track, container);
          });

          publication.on("unsubscribed", (track) => {
            console.log('[RemoteParticipant:trackPublished:unsubscribed]: ', { track });
            detachTrack(track);
          });
        }
      };

      const attachTrack = async (track, container) => {
        const element = track.attach();
        if (track.kind === "audio") {
          element?.setSinkId && element?.setSinkId(currentSpeaker);
        }
        element?.setAttribute &&
          element.setAttribute("id", `video-room-participant-${participant.identity}-${track.kind}`);
        container.appendChild(element);
      };

      const detachTrack = (track) => {
        if (track) {
          track.detach().forEach((element) => {
            element.remove();
          });
        }
      };

      const handleTrackPubished = async (publication) => {
        await trackPublished(publication, container);
      };

      const handleTrackUnpublished = async (publication) => {
        console.log('[RemoteParticipant:handleTrackUnpublished]: ', { publication });
        await detachTrack(publication.track);
      };

      participant.tracks.forEach(async (publication) => {
        await trackPublished(publication, container);
      });

      participant.on("trackPublished", handleTrackPubished);
      participant.on("trackUnpublished", handleTrackUnpublished);

      return () => {
        participant.off("trackPublished", handleTrackPubished);
        participant.off("trackUnpublished", handleTrackUnpublished);
      };
    }
  }, []);

  useEffect(() => {
    if (!visibility) {
      setBlur(false);
    }
  }, [visibility]);

  const remoteControlList = (
    <Menu>
      <MenuItem
        key={`${identity}-ToggleAudio`}
        onClick={() => {
          toggleAudio();
        }}
      >
        <ActionItem data-type="ToggleAudio">{!audioTrack || !audioOn ? "Unmute" : "Mute"}</ActionItem>
      </MenuItem>
      <MenuItem
        key={`${identity}-ToggleVideo`}
        onClick={() => {
          toggleVideo();
        }}
      >
        <ActionItem data-type="ToggleVideo">{videoOn ? "Disable Remote Video" : "Enable Remote Video"}</ActionItem>
      </MenuItem>
      {agency.settings["enableVideoRecipientControls"] && (
        <MenuItem
          key={`${identity}-SwitchCamera`}
          onClick={() => {
            onSwitchCamera && onSwitchCamera(participant.identity);
          }}
        >
          <ActionItem data-type="SwitchCamera">Switch Camera</ActionItem>
        </MenuItem>
      )}
      <MenuItem
        key={`${identity}-ToggleVideoBlur`}
        onClick={() => {
          setBlur(!blur);
        }}
      >
        <ActionItem data-type="ToggleVideoBlur">{blur ? "Unblur" : "Blur"}</ActionItem>
      </MenuItem>
      <MenuItem
        key={`${identity}-ShareLocation`}
        onClick={() => {
          onShareLocation && onShareLocation(participant.identity);
        }}
      >
        <ActionItem data-type="ShareLocation">Request Location</ActionItem>
      </MenuItem>
      <MenuItem
        key={`${identity}-RemoveParticipant`}
        onClick={() => {
          removeParticipant();
        }}
      >
        <ActionItem data-type="RemoveParticipant">Remove Participant</ActionItem>
      </MenuItem>
    </Menu>
  );

  return (
    <ParticipantWrap
      id={`video-room-participant-${participant.identity}`}
      vertical={vertical}
      numberOfParticipants={numberOfParticipants}
      className={`
        ${minimized || (isLocalParticipant && !isCameraOn) ? " inactive" : " active"}
        ${!isLocalParticipant ? " remote-participant" : " self-participant"}
        ${isLocalParticipant ? (isCameraOn ? " video-on" : " video-off") : (videoOn ? " video-on" : " video-off")}
      `}
    >
      <ParticipantTop>
        {!isLocalParticipant && <Identity>{formatPhoneNumber(identity)}</Identity>}
        {showRemoteControl && (
          <Dropdown trigger={["click"]} overlay={remoteControlList}>
            <ActionButton data-type="ToggleRemoteActions">
              <ThreeDot />
            </ActionButton>
          </Dropdown>
        )}
      </ParticipantTop>
      {!isLocalParticipant && (
        <ParticipantBottom>
          <LanguageSelector
            onSelect={(selectedLanguage) => {
              onSelectLanguage && onSelectLanguage(identity, selectedLanguage);
            }}
            dialects={dialects}
            currentDialect={defaultDialect}
            disabled={!recipientFlow ? (isLocalParticipant ? true : false) : isLocalParticipant ? false : true}
          />
          {!visibility && <EyeSlashSolid />}
          {(!audioTrack || !audioOn) && <MicMute />}
          {(!videoTrack || !videoOn) && <VideoOff />}
        </ParticipantBottom>
      )}
      {!recipientFlow && !isLocalParticipant && blur && visibility && <BlurView />}
      {!recipientFlow && !isLocalParticipant && !visibility && (
        <BlackVisibilityView>
          <h3>Recipient Video has been backgrounded</h3>
        </BlackVisibilityView>
      )}
      {!videoOn && !isLocalParticipant && (
        <VideoBox>
          {recipientFlow && <img src={agency.settings.agencyLogo} />}
          {!recipientFlow && <img src={Avatar} />}
        </VideoBox>
      )}
    </ParticipantWrap>
  );
};

const mapStateToProps = ({ agency, app, conversations }: IRootState) => ({
  app,
  agency,
  conversations
});

export const Participant = connect(mapStateToProps, {})(XParticipant);
