import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback
} from "react";
import { Participant } from "./Participant";
import { useRoom } from "use-twilio-video";
import { createLocalVideoTrack, createLocalAudioTrack } from "twilio-video";
import { IDialectEntity, ConveySocket, ConveyApi, IConveyVideoRoom, IVideoRoomParticipant } from "@utils";
import { FlexCenterRow, UnderlinedSpan, ItalicSpan } from "@components/styles";
import {
  VideoContainer,
  TranscriptionContainer,
  VideoWrap,
  MediaContainer,
  DeviceSelectorContainer,
  VideoActionButton
} from "./videoStyles";
import { LocationModal } from "@components/LocationModal";
import { DeviceSelector } from "./DeviceSelector";
import { LanguageSelector } from "./LanguageSelector";
import { SelectCameraDevice } from "./SelectCameraDevice";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import {
  setVideoNoShow,
  setVideoRoomCount,
  setActiveRoomData,
  setVideoRoomLastActivity,
  setConversationLocation
} from "@actions";
import { formatPhoneNumber, getLastLocationTime } from "@lib/utils/helpers";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
// @ts-ignore
import HangUp from "@assets/icons/hangup.svg";

export interface IVideoRoomProps {
  conversations: IRootState["conversations"];
  app: IRootState["app"];
  agency: IRootState["agency"];
  videoToken: string;
  $transcriptionContainer: string;
  roomName: string;
  conversationId: string;
  identity: string;
  dialects: IDialectEntity[];
  agentPhoneNumber: string;
  agencyId: string;
  recipientId: string;
  recipientNumber: string;
  phoneNumber: string;
  roomId: string;
  correlationId: string;
  blur: boolean;
  enableAudio: boolean;
  enableVideo: boolean;
  enableTranscribe: boolean;
  recipientFlow: boolean;
  setConversationLocation: typeof setConversationLocation;
  setVideoNoShow: typeof setVideoNoShow;
  setVideoRoomCount: typeof setVideoRoomCount;
  setActiveRoomData: typeof setActiveRoomData;
  setVideoRoomLastActivity: typeof setVideoRoomLastActivity;
  onError: (error: Error) => void;
  onVideoRoomEnded: (room: IConveyVideoRoom) => void;
  onVideoRoomParticipantRemoved: (participant: IVideoRoomParticipant) => void;
  onVideoRoomParticipantAdded: (participant: IVideoRoomParticipant) => void;
}

const XVideoRoom: FunctionComponent<IVideoRoomProps> = forwardRef(
  (
    {
      agency,
      roomId,
      videoToken,
      roomName,
      dialects,
      conversations,
      conversationId,
      identity,
      phoneNumber,
      onError,
      blur: initialBlur,
      enableAudio,
      app,
      enableVideo,
      enableTranscribe,
      recipientFlow,
      onVideoRoomEnded,
      onVideoRoomParticipantRemoved,
      onVideoRoomParticipantAdded,
      setConversationLocation,
      $transcriptionContainer,
      recipientNumber,
      recipientId,
      setVideoRoomLastActivity
    }: IVideoRoomProps,
    ref
  ) => {
    const currentConversation = conversations[conversationId];
    const initialRecipientLanguages = {};
    currentConversation?.entity?.recipients?.forEach((recipient) => {
      initialRecipientLanguages[recipient.number] = recipient.language;
    });
    const {
      room,
      error,
      connectRoom,
      disconnectRoom,
      localParticipant,
      remoteParticipants: participants,
      isCameraOn,
      isMicrophoneOn,
      toggleCamera,
      toggleMicrophone
    } = useRoom(
      recipientFlow
        ? {
            enableAudio: identity === "Authorized Participant" ? false : true,
            enableVideo: identity === "Authorized Participant" ? false : true
          }
        : { enableAudio, enableVideo }
    );
    const [endLoading, setEndLoading] = useState(false);
    const [locationIsFresh, setLocationIsFresh] = useState(false);
    const [blur, setBlur] = useState(initialBlur);
    const [active, setActive] = useState(true);
    const [showSelectCamModal, setShowSelectCamModal] = useState(false);
    const [selectCameraList, setSelectCameraList] = useState([]);
    const [selectCameraIdentity, setSelectCameraIdentity] = useState("");
    const [disconnected, setDisconnected] = useState(false);
    const [, setTranscriptions] = useState([]);
    const [, setTranscriptionIdentities] = useState([]);
    const [recipientLanguages, setRecipientLanguages] = useState(initialRecipientLanguages);
    const [mic, setMic] = useState(null);
    const [camera, setCamera] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [speaker, setSpeaker] = useState(null);
    const [cameraList, setCameraList] = useState([]);
    const [speakerList, setSpeakerList] = useState([]);
    const [micList, setMicList] = useState([]);
    const [, setCurrentRecipient] = useState(recipientId);
    const [showMap, setShowMap] = useState(0);
    const [nextNavShown, setNextNavShown] = useState(false);
    const [transcriptionActive, setTranscriptionActive] = useState(enableTranscribe || false);
    const [remoteParticipants, setRemoteParticipants] = useState([]);
    const wsConnectInterval = useRef<NodeJS.Timer | null>(null);
    const speechRef = useRef(null);
    const wsRef = useRef(null);
    const transcriptsRef = useRef([]);
    const transcriptsIdentitiesRef = useRef([]);
    const recipientLanguagesRef = useRef(initialRecipientLanguages);
    const selfIdentityRef = useRef();
    const senderLanguageRef = useRef();
    const senderLanguage = recipientFlow ? recipientLanguagesRef.current[recipientNumber] || "en" : "en";
    const conversationIDRef = useRef(conversationId);
    const recipientIDRef = useRef(recipientId);
    const audioStreamRef = useRef(null);
    const intervalIDRef = useRef(null);
    const [mediaDevices, setMediaDevices] = useState([]);
    const [initializedMediaDevices, setInitializedMediaDevices] = useState(false);
    const useDevices = !!navigator.mediaDevices;

    // const initializedMediaDevices = useMemo(() => {
    //   return mediaDevices && mediaDevices.length > 0 ? true : false;
    // }, [mediaDevices]);

    useEffect(() => {
      senderLanguageRef.current = senderLanguage;
    }, [senderLanguage]);

    const scrollToBottom = () => {
      setTimeout(() => {
        const wrapperEle = document.getElementById(`transcription-container-${conversationId}`);
        if (wrapperEle) {
          wrapperEle.scrollTo(0, 100000);
        }

        if ($transcriptionContainer) {
          const spareWrapperEle = document.getElementById($transcriptionContainer);
          if (spareWrapperEle && wrapperEle) {
            spareWrapperEle.replaceChildren(wrapperEle.cloneNode(true));
            setTimeout(() => {
              spareWrapperEle.scrollTo(0, 100000);
            }, 500);
          }
        }
      }, 250);
    };

    const joinRoom = useCallback(() => {
      if (recipientFlow) {
        window.location.reload();
      }
    }, [recipientFlow]);

    const leaveRoom = async () => {
      if (localVideoTrack) {
        try {
          await localParticipant.unpublishTrack(localVideoTrack);
          localVideoTrack.stop();
          localVideoTrack.detach().forEach((element) => {
            element.remove();
          });
          setLocalVideoTrack(null);
        } catch (err) {
          console.log(err);
        }
      }

      if (localAudioTrack) {
        try {
          await localParticipant.unpublishTrack(localAudioTrack);
          localAudioTrack.stop();
          localAudioTrack.detach().forEach((element) => {
            element.remove();
          });
          setLocalAudioTrack(null);
        } catch (err) {
          console.log(err);
        }
      }

      disconnectRoom();
      stopSpeechRecognization();
      setDisconnected(true);
      setCamera(null);
      setMic(null);
      setSpeaker(null);
    };

    const endRoom = async () => {
      stopSpeechRecognization();
      leaveRoom();
      onVideoRoomEnded && onVideoRoomEnded({ roomId, conversationId, roomName });
      setActive(false);
    };

    const putRecipientLanguage = async (recipientId: string, language: string) => {
      await ConveyApi.putRecipientLanguage({
        language,
        recipientId
      });
      ConveySocket.getInstance().socketEmit(ConveySocket.SHARE_INFORMATION_CMD, {
        conversationId,
        type: "recognition-language",
        data: { [recipientId]: language }
      });
    };

    const onOpenSelectCamDeviceModal = (identity) => {
      if (!showSelectCamModal) {
        const cameraList = app.remoteRecipientCamSettings[identity]?.cameraList;
        if (cameraList?.length > 0) {
          setSelectCameraIdentity(identity);
          setSelectCameraList(cameraList);
          setShowSelectCamModal(true);
        } else {
          ConveySocket.getInstance().socketEmit(ConveySocket.VIDEO_REMOTE_CONTROL_CMD, {
            event: "toggleCamera",
            conversationId,
            identity
          });
        }
      }
    };

    const onSelectCameraDevice = useCallback(
      async (deviceId: string) => {
        try {
          if (camera !== deviceId) {
            if (localVideoTrack) {
              const publication = await localParticipant.unpublishTrack(localVideoTrack);
              localVideoTrack.stop();
              localVideoTrack.detach().forEach((element) => {
                element.remove();
              });
              setLocalVideoTrack(null);
              await new Promise((resolve) => setTimeout(resolve, 300));
            }

            const videoTrack = await createLocalVideoTrack({
              deviceId: { exact: deviceId }
            });
            await new Promise((resolve) => setTimeout(resolve, 300));
            console.log("Created Local Video Track >>>>>>", videoTrack);

            setLocalVideoTrack(videoTrack);
            const publication = await localParticipant.publishTrack(videoTrack);
            await new Promise((resolve) => setTimeout(resolve, 300));
            setCamera(deviceId);
          }
        } catch (err) {
          console.log("[onSelectCameraDevice] Error: ", error);
        }
      },
      [localVideoTrack, localParticipant, setLocalVideoTrack, setCamera, camera]
    );

    const onSelectMicDevice = useCallback(
      async (deviceId) => {
        if (mic !== deviceId) {
          setMic(deviceId);

          if (localAudioTrack) {
            await localParticipant.unpublishTrack(localAudioTrack);
            localAudioTrack.stop();
            localAudioTrack.detach().forEach((element) => {
              element.remove();
            });
            setLocalAudioTrack(null);
          }

          const audioTrack = await createLocalAudioTrack({
            deviceId: { exact: deviceId }
          }).catch((error) => {
            console.log("Error: ", error);
          });

          if (audioTrack) {
            setLocalAudioTrack(audioTrack);
            await localParticipant.publishTrack(audioTrack);
          }
        }
      },
      [localAudioTrack, localParticipant, setLocalAudioTrack, setMic, mic]
    );

    const updateSelfLanguage = useCallback(
      async (data: any) => {
        const { code: langCode } = data;
        const localRecipient = currentConversation?.entity?.recipients?.find(
          (recipient) => recipient.number === localParticipant.identity
        );
        if (localRecipient?.recipient_id) {
          await putRecipientLanguage(localRecipient?.recipient_id, langCode);
          const currentRecipientLanguages = { ...recipientLanguages };
          currentRecipientLanguages[localRecipient.number] = langCode;
          setRecipientLanguages(currentRecipientLanguages);
        }
      },
      [putRecipientLanguage, recipientLanguages]
    );

    const updateRecipientLanguage = useCallback(
      (data: any) => {
        if (data.recipient_id !== recipientIDRef.current) {
          const identities = remoteParticipants.map((p) => p.identity);
          if (identities.includes(data.number)) {
            setRecipientLanguages({ ...recipientLanguages, [data.number]: data.language });
          }
        } else {
          setRecipientLanguages({ ...recipientLanguages, [data.number]: data.language });
        }
      },
      [recipientLanguages, remoteParticipants]
    );

    const remoteControlChangeLang = useCallback(
      (data: any) => {
        const { identity, data: lang } = data;
        if (identity !== localParticipant.identity) {
          return;
        }
        updateSelfLanguage({ code: lang });
      },
      [updateSelfLanguage, localParticipant]
    );

    const remoteControlSwitchCamera = useCallback(
      (data: any) => {
        const { identity, deviceId } = data;
        if (identity === selfIdentityRef.current) {
          if (deviceId) {
            const findDevice = cameraList.find((device) => device.deviceId === deviceId);
            if (findDevice) {
              onSelectCameraDevice(findDevice.deviceId);
            } else {
              const currentDeviceIndex = cameraList.findIndex((device) => device.deviceId === camera);
              if (cameraList.length > 1) {
                const nextDevice =
                  currentDeviceIndex + 1 <= cameraList.length - 1 ? cameraList[currentDeviceIndex + 1] : cameraList[0];
                onSelectCameraDevice(nextDevice.deviceId);
              }
            }
          } else {
            const currentDeviceIndex = cameraList.findIndex((device) => device.deviceId === camera);
            if (cameraList.length > 1) {
              const nextDevice =
                currentDeviceIndex + 1 <= cameraList.length - 1 ? cameraList[currentDeviceIndex + 1] : cameraList[0];
              onSelectCameraDevice(nextDevice.deviceId);
            }
          }
        }
      },
      [onSelectCameraDevice, cameraList]
    );

    const onSelectRemoteCamera = (data: any) => {
      const { identity, deviceId } = data;
      const payload = {
        event: "toggleCamera",
        conversationId,
        identity,
        deviceId
      };
      ConveySocket.getInstance().socketEmit(ConveySocket.VIDEO_REMOTE_CONTROL_CMD, payload);
      setShowSelectCamModal(false);
      setSelectCameraIdentity("");
      setSelectCameraList([]);
    };

    const remoteControlCameraOnOff = useCallback(
      (data: any) => {
        try {
          const { identity } = data;
          if (identity === selfIdentityRef.current) {
            toggleCamera();
          }
        } catch (err) {
          console.log("[remoteControlCameraOnOff] Error: ", err);
        }
      },
      [toggleCamera]
    );

    const remoteControlMicOnOff = useCallback(
      (data: any) => {
        const { identity } = data;
        if (identity === selfIdentityRef.current) {
          toggleMicrophone();
        }
      },
      [toggleMicrophone]
    );

    const postLocation = async (coords: GeolocationCoordinates) => {
      await ConveyApi.postRecipientGeoLocations({
        recipientId: recipientIDRef.current,
        lat: coords.latitude,
        lng: coords.longitude,
        latlng_accuracy: coords.accuracy,
        altitude: coords.altitude,
        altitude_accuracy: coords.altitudeAccuracy
      });
    };

    const geoSuccessHandler = (position: GeolocationPosition) => {
      postLocation(position.coords);
    };

    const geoErrorHandler = (error: GeolocationPositionError) => {
      console.log(error);
      alert("Location settings are not enabled in your browser.");
    };

    const shareLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(geoSuccessHandler, geoErrorHandler);
        navigator.geolocation.watchPosition(geoSuccessHandler, geoErrorHandler);
      } else {
        alert("Your browser does not support geolocation API.");
      }
    };

    const remoteControlShareLocation = useCallback(
      (data: any) => {
        const { identity } = data;
        if (identity === selfIdentityRef.current && confirm("Will you share your location?")) {
          shareLocation();
        }
      },
      [shareLocation]
    );

    const shareVisibilityInformation = (visibility: boolean) => {
      if (recipientFlow) {
        ConveySocket.getInstance().socketEmit(ConveySocket.VIDEO_REMOTE_CONTROL_CMD, {
          event: "visibilityChange",
          conversationId,
          identity: selfIdentityRef.current,
          data: { visibility }
        });
      }
    };

    const handlePageVisibility = () => {
      shareVisibilityInformation(!document.hidden);
    };

    const handleWindowBlur = () => {
      shareVisibilityInformation(false);
    };

    const handleWindowFocus = () => {
      shareVisibilityInformation(!document.hidden);
    };

    useImperativeHandle(ref, () => ({
      execEndRoom(execConversationId: string) {
        if (conversationId === execConversationId) {
          endRoom();
        }
      },
      execLeaveRoom(execConversationId: string) {
        if (conversationId === execConversationId) {
          leaveRoom();
        }
      }
    }));

    useEffect(() => {
      ConveySocket.init(ConveyApi.readAccessToken());
      ConveySocket.getInstance()
        .listen(
          ConveySocket.CREATE_MESSAGE,
          (data) => {
            if (data.conversation_id !== conversationIDRef.current) {
              return;
            }
            if (!recipientIDRef.current && data.direction === "outbound") {
              recipientIDRef.current = data.recipient_id;
              setCurrentRecipient(data.recipient_id);
            }
          },
          true
        )
        .listen(
          ConveySocket.ADD_VIDEO_ROOM_PARTICIPANT,
          (data) => {
            if (data.conversation_id !== conversationIDRef.current) {
              return;
            }
            onVideoRoomParticipantAdded && onVideoRoomParticipantAdded(data);
          },
          true
        )
        .listen(
          ConveySocket.START_TRANSCRIPTION,
          (data) => {
            if (data.conversationId !== conversationIDRef.current) {
              return;
            }
            setTranscriptionActive(true);
            setTimeout(() => {
              scrollToBottom();
            }, 500);
          },
          true
        )
        .listen(
          ConveySocket.STOP_TRANSCRIPTION,
          (data) => {
            if (data.conversationId !== conversationIDRef.current) {
              return;
            }
            setTranscriptionActive(false);
          },
          true
        )
        .listen(
          ConveySocket.REMOVE_VIDEO_ROOM_PARTICIPANT,
          (data) => {
            if (data.conversation_id !== conversationIDRef.current) {
              return;
            }
            onVideoRoomParticipantRemoved && onVideoRoomParticipantRemoved(data);
          },
          true
        )
        .listen(
          ConveySocket.ADD_RECIPIENT_GEOLOCATION,
          (data) => {
            if (data.recipient_id === recipientIDRef.current) {
              const {
                lng,
                lat,
                normalized_address,
                updated_at,
                latlng_accuracy,
                altitude,
                altitude_accuracy,
                hat,
                hat_accuracy
              } = data;
              setConversationLocation(conversationIDRef.current, {
                lng,
                lat,
                hat,
                altitude,
                normalizedAddress: normalized_address,
                updatedAt: updated_at,
                latlngAccuracy: latlng_accuracy,
                altitudeAccuracy: altitude_accuracy,
                hatAccuracy: hat_accuracy
              });
            }
          },
          true
        )
        .listen(
          ConveySocket.UPDATE_RECIPIENT_LANGUAGE,
          (data) => {
            if (data.recipient_id !== recipientIDRef.current) {
              return;
            }
            setRecipientLanguages({ ...recipientLanguages, [data.number]: data.language });
            recipientLanguagesRef.current = { ...recipientLanguagesRef.current, [data.number]: data.language };
          },
          true
        )
        .listen(
          ConveySocket.RECEIVE_VIDEO_TRANSCRIPTION,
          (data) => {
            if (data.conversationId !== conversationIDRef.current) {
              return;
            }
            const currentLength = transcriptsRef.current.length;
            const { identity, status, transcription, translations, languages, id } = data;
            const dialogue =
              languages && languages.to.substring(0, 2) == (senderLanguageRef.current || "en") ? translations.to : null;
            const formattedIdentity = formatPhoneNumber(identity);
            const line = `${formattedIdentity}: ${dialogue}`;

            if (!transcriptsIdentitiesRef.current.includes(formattedIdentity)) {
              transcriptsIdentitiesRef.current.push(formattedIdentity);
            }

            if (!dialogue || dialogue == "") {
              return;
            }

            if (currentLength > 0) {
              const lastTranscription = transcriptsRef.current[currentLength - 1];
              const lastTranscriptIdentity = lastTranscription ? formatPhoneNumber(lastTranscription.identity) : "";

              if (
                lastTranscription &&
                formattedIdentity == lastTranscriptIdentity &&
                dialogue.toLowerCase() == lastTranscription.dialogue.toLowerCase()
              ) {
                return;
              }

              if (lastTranscription.status == "recognized") {
                transcriptsRef.current = [
                  ...transcriptsRef.current,
                  { line, dialogue, identity: formattedIdentity, transcription, status, id }
                ];
              } else {
                if (formattedIdentity != lastTranscriptIdentity) {
                  transcriptsRef.current = [
                    ...transcriptsRef.current,
                    { line, dialogue, identity: formattedIdentity, transcription, status, id }
                  ];
                } else {
                  transcriptsRef.current.pop();
                  transcriptsRef.current = [
                    ...transcriptsRef.current,
                    { line, dialogue, identity: formattedIdentity, transcription, status, id }
                  ];
                }
              }
            } else {
              transcriptsRef.current = [
                ...transcriptsRef.current,
                { line, dialogue, identity: formattedIdentity, transcription, status, id }
              ];
            }
            setTranscriptions(transcriptsRef.current);
            scrollToBottom();
          },
          true
        );
    }, []);

    useEffect(() => {
      if (room && !disconnected) {
        ConveySocket.getInstance()
          .listen(ConveySocket.DISABLE_CAMERA, remoteControlCameraOnOff)
          .listen(ConveySocket.ENABLE_CAMERA, remoteControlCameraOnOff)
          .listen(ConveySocket.DISABLE_AUDIO, remoteControlMicOnOff)
          .listen(ConveySocket.ENABLE_AUDIO, remoteControlMicOnOff)
          .listen(ConveySocket.TOGGLE_CAMERA, remoteControlSwitchCamera)
          .listen(ConveySocket.CHANGE_RECOG_LANG, remoteControlChangeLang)
          .listen(ConveySocket.SHARE_LOCATION, remoteControlShareLocation)
          .listen(ConveySocket.UPDATE_RECIPIENT_LANGUAGE, updateRecipientLanguage, true);
      }

      room?.on("disconnected", leaveRoom);

      return () => {
        ConveySocket.getInstance()
          .listenOff(ConveySocket.ENABLE_CAMERA, remoteControlCameraOnOff)
          .listenOff(ConveySocket.DISABLE_CAMERA, remoteControlCameraOnOff)
          .listenOff(ConveySocket.ENABLE_AUDIO, remoteControlMicOnOff)
          .listenOff(ConveySocket.DISABLE_AUDIO, remoteControlMicOnOff)
          .listenOff(ConveySocket.TOGGLE_CAMERA, remoteControlSwitchCamera)
          .listenOff(ConveySocket.CHANGE_RECOG_LANG, remoteControlChangeLang)
          .listenOff(ConveySocket.SHARE_LOCATION, remoteControlShareLocation)
          .listenOff(ConveySocket.UPDATE_RECIPIENT_LANGUAGE, updateRecipientLanguage);

        room?.off("disconnected", leaveRoom);
      };
    }, [
      room,
      disconnected,
      remoteControlCameraOnOff,
      remoteControlMicOnOff,
      remoteControlSwitchCamera,
      remoteControlChangeLang,
      updateRecipientLanguage,
      remoteControlShareLocation,
      leaveRoom
    ]);

    useEffect(
      () => {
        if (!disconnected && !room && videoToken && roomName) {
          const requestMediaAccess = async () => {
            let isCameraAvailable = false;
            await navigator.mediaDevices.getUserMedia({
              video: true
            }).then((stream) => {
              isCameraAvailable = true;
              stream.getTracks().forEach(track => {
                track.stop();
              })
            }).catch(_ => {
              isCameraAvailable = false;
            });
        
            const mediaStreamConstraints = {
              video: isCameraAvailable,
              audio: true
            };
        
            const media = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
              .catch(mediaError => {
                return mediaError;
              });
        
            if (media.message) {
              return media;
            } else {
              const tracks = media.getTracks();
              tracks.forEach(track => {
                track.stop();
              });
            }
        
            return undefined;
          };
          (async () => {
            let hasVideo = false;
            let hasAudio = false;
            console.log('before getting permissions >>>>>>>>>>>>>')
            if (useDevices) {
              await requestMediaAccess();
              console.log('before getting media device >>>>>>>>>>>>>')
              const mediaDevices = await navigator.mediaDevices.enumerateDevices();
              const videoDevices = mediaDevices.filter((device) => device.kind === "videoinput" && device.deviceId);
              const audioDevices = mediaDevices.filter((device) => device.kind === "audioinput" && device.deviceId);
              hasVideo = videoDevices.length > 0;
              hasAudio = audioDevices.length > 0;
              console.log({videoDevices, audioDevices});
              console.log({hasVideo, hasAudio});
            }
            connectRoom({
              token: videoToken,
              options: {
                name: roomName,
                dominantSpeaker: true,
                type: "group-small",
                maxParticipants: 4,
                video: hasVideo,
                audio: hasAudio,
                tracks: [],
              }
            });
          })();
        }

        return () => disconnectRoom();
      },
      [
        disconnected,
        connectRoom,
        disconnectRoom,
        room,
        roomName,
        videoToken,
        useDevices
      ]
    );

    const getMediaDeviceList = (mediaDevices) => {
      const localCameraList = [];
      const localMicList = [];
      const localSpeakerList = [];
      let localCamera, localSpeaker, localMic;
      mediaDevices.forEach((mediaDevice) => {
        switch (mediaDevice.kind) {
          case "videoinput":
            if (mediaDevice.deviceId) {
              localCamera = localCameraList.find((cameraDevice) => {
                return cameraDevice.deviceId === mediaDevice.deviceId;
              });
              if (!localCamera) {
                localCameraList.push(mediaDevice);
              }
            }
            break;
          case "audioinput":
            if (mediaDevice.deviceId) {
              localMic = localMicList.find((micDevice) => {
                return micDevice.deviceId === mediaDevice.deviceId;
              });
              if (!localMic) {
                localMicList.push(mediaDevice);
              }
            }
            break;
          case "audiooutput":
            if (mediaDevice.deviceId) {
              localSpeaker = localSpeakerList.find((speakerDevice) => {
                return speakerDevice.deviceId === mediaDevice.deviceId;
              });
              if (!localSpeaker) {
                localSpeakerList.push(mediaDevice);
              }
            }
            break;
        }
      });

      console.log(`localCameraList: ${JSON.stringify(localCameraList)}`);
      console.log(`localMicList: ${JSON.stringify(localMicList)}`);
      console.log(`localSpeakerList: ${JSON.stringify(localSpeakerList)}`);

      return {
        localCameraList,
        localMicList,
        localSpeakerList
      };
    };

    const shareVideoSettings = ({ cameraList }) => {
      ConveySocket.getInstance().socketEmit(ConveySocket.SHARE_INFORMATION_CMD, {
        conversationId,
        type: "share-video-settings",
        data: {
          identity: selfIdentityRef.current,
          mVideoSettings: { cameraList }
        }
      });
    };

    useEffect(() => {
      if (room && useDevices) {
        (async () => {
          console.log('before getting media device [room created] >>>>>>>>>>>>>')
          const devices = await navigator.mediaDevices.enumerateDevices();
          const mediaDeviceList = getMediaDeviceList(devices);
          const { localCameraList, localMicList, localSpeakerList } = mediaDeviceList;
          console.log(`devices: ${JSON.stringify(mediaDeviceList)}`);

          if (!mic && localMicList.length > 0) {
            const localAudioTracks = Array.from(localParticipant.audioTracks.values())
              .filter((publication) => {
                return publication;
              })
              .map((publication: any) => {
                return publication.track;
              });
            if (localAudioTracks.length > 0) {
              setLocalAudioTrack(localAudioTracks[0]);
              setMic(localMicList[0].deviceId);
            } else {
              setMic(localMicList[0].deviceId);
              if (!localAudioTrack) {
                const audioTrack = await createLocalAudioTrack({
                  deviceId: { exact: localMicList[0].deviceId }
                }).catch((error) => {
                  console.log("Error: ", error);
                });

                if (audioTrack) {
                  await localParticipant.publishTrack(audioTrack);
                  setLocalAudioTrack(audioTrack);
                }
              }
            }
          }

          if (!camera && localCameraList.length > 0) {
            const localVideoTracks = Array.from(localParticipant.videoTracks.values())
              .filter((publication) => {
                return publication;
              })
              .map((publication: any) => {
                return publication.track;
              });
            if (localVideoTracks.length > 0) {
              setLocalVideoTrack(localVideoTracks[0]);
              setCamera(localCameraList[0].deviceId);
            } else {
              setCamera(localCameraList[0].deviceId);
              if (!localVideoTrack) {
                const videoTrack = await createLocalVideoTrack({
                  deviceId: { exact: localCameraList[0].deviceId }
                }).catch((error) => {
                  console.log("Error: ", error);
                });

                if (videoTrack) {
                  await localParticipant.publishTrack(videoTrack);
                  setLocalVideoTrack(videoTrack);
                }
              }
            }
          }

          if (!speaker && localSpeakerList.length > 0) {
            setSpeaker(localSpeakerList[0].deviceId);
          }

          setCameraList(localCameraList);
          setSpeakerList(localSpeakerList);
          setMicList(localMicList);
          setMediaDevices([...devices]);
          setInitializedMediaDevices(true);
          shareVideoSettings({ cameraList: localCameraList });
        })();
      }
    }, [room, useDevices]);

    const handleDeviceChange = useCallback(async () => {
      const oldMediaDevices = [...mediaDevices];
      const newMediaDevices = await navigator.mediaDevices.enumerateDevices();

      const addedDevices = newMediaDevices.filter(
        (device) => !oldMediaDevices.some((d) => d.deviceId === device.deviceId)
      );
      const removedDevices = oldMediaDevices.filter(
        (device) => !newMediaDevices.some((d) => d.deviceId === device.deviceId)
      );

      const { localCameraList, localMicList, localSpeakerList } = getMediaDeviceList(newMediaDevices);
      if (addedDevices.length > 0) {
        addedDevices.forEach((mediaDevice) => {
          switch (mediaDevice.kind) {
            case "videoinput":
              onSelectCameraDevice(mediaDevice.deviceId);
              break;
            case "audioinput":
              onSelectMicDevice(mediaDevice.deviceId);
              break;
            case "audiooutput":
              setSpeaker(mediaDevice.deviceId);
              break;
          }
        });
      } else if (removedDevices.length > 0) {
        removedDevices.forEach((mediaDevice) => {
          switch (mediaDevice.kind) {
            case "videoinput":
              if (camera === mediaDevice.deviceId) {
                onSelectCameraDevice(localCameraList[0].deviceId);
              }
              break;
            case "audioinput":
              if (mic === mediaDevice.deviceId) {
                onSelectMicDevice(localMicList[0].deviceId);
              }
              break;
            case "audiooutput":
              if (speaker === mediaDevice.deviceId) {
                setSpeaker(localSpeakerList[0].deviceId);
              }
              break;
          }
        });
      }

      setCameraList(localCameraList);
      setSpeakerList(localSpeakerList);
      setMicList(localMicList);
      setMediaDevices([...newMediaDevices]);
      shareVideoSettings({ cameraList: localCameraList });
    }, [camera, mic, speaker, mediaDevices, onSelectMicDevice, onSelectCameraDevice]);

    useEffect(() => {
      if (initializedMediaDevices && useDevices) {
        navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);
      }

      return () => {
        if (initializedMediaDevices && useDevices) { 
          navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
        }
      };
    }, [initializedMediaDevices, handleDeviceChange, useDevices]);

    useEffect(() => {
      if (!conversationId) {
        return;
      }
      (async () => {
        const messagesResponse = await ConveyApi.getSourceMessages({ source: "transcribe", conversationId });
        transcriptsRef.current = [
          ...transcriptsRef.current,
          ...messagesResponse
            .filter((e) => e.source === "transcribe")
            .map((m) => {
              const realDirection = recipientFlow ? (m.direction === "inbound" ? "outbound" : "inbound") : m.direction;
              const bodyText = realDirection === "outbound" ? m.body : m.body_transl ? m.body_transl : m.body;
              const formattedIdentity =
                m.direction === "outbound"
                  ? app.agencyName
                  : formatPhoneNumber(m.recipient_label ? m.recipient_label : m.recipient_number);
              if (!transcriptsIdentitiesRef.current.includes(formattedIdentity)) {
                transcriptsIdentitiesRef.current.push(formattedIdentity);
              }

              return {
                id: m.message_id,
                identity: formattedIdentity,
                dialogue: bodyText,
                line: [formattedIdentity, bodyText].join(": ")
              };
            })
        ];
        setTranscriptions(transcriptsRef.current);
        setTimeout(() => {
          scrollToBottom();
        }, 500);
      })();

      return () => {
        if (wsConnectInterval.current) {
          clearInterval(wsConnectInterval.current);
        }
      };
    }, [conversationId]);

    useEffect(() => {
      const sids = participants.map((p) => p.sid);
      const filteredParticipants = participants.filter((p, pIndex) => !sids.includes(p.sid, pIndex + 1));
      setRemoteParticipants(filteredParticipants.filter((p) => p.identity !== "Authorized Participant"));
      setVideoRoomLastActivity("participantChanged");
    }, [participants]);

    const openWs = () => {
      const wsUrl = `${
        process.env.WS_RTP_HOST
      }?conversationId=${conversationId}&phoneNumber=${phoneNumber}&primaryRecipient=${phoneNumber}&agencyId=${
        app.agencyId
      }&recipientLanguage=${
        recipientFlow ? recipientLanguagesRef.current[recipientNumber] || "en-US" : "en-US"
      }&senderLanguage=${senderLanguage}&source=video&recipientFlow=${
        recipientFlow ? "true" : "false"
      }&identity=${identity}&role=${
        recipientFlow ? "recipient" : "responder"
      }&transcribeProvider=azure&encoding=linear16`;
      wsRef.current = new WebSocket(wsUrl);
      wsRef.current.binaryType = "arraybuffer";
    };

    const startSpeechRecognization = () => {
      if (!useDevices) {
        return;
      }
      if (!wsRef.current) {
        openWs();
      }
      if (wsRef.current.readyState === WebSocket.OPEN) {
        initAudio();
      } else {
        wsRef.current.addEventListener("open", () => {
          initAudio();
        });
        wsRef.current.addEventListener("close", () => {
          if (!wsConnectInterval.current) {
            wsConnectInterval.current = setInterval(() => {
              if (wsRef.current.readyState !== WebSocket.OPEN) {
                openWs();
              }
            }, 1000);
          }
        });
        wsRef.current.addEventListener("error", () => {
          if (!wsConnectInterval.current) {
            wsConnectInterval.current = setInterval(() => {
              if (wsRef.current.readyState !== WebSocket.OPEN) {
                openWs();
              }
            }, 1000);
          }
        });
      }
    };

    const RECORDER_PROVIDER = "rtc";
    const initAudio = () => {
      (async () => {
        if (!speechRef.current) {
          const constraints = { audio: true };
          audioStreamRef.current = await navigator.mediaDevices.getUserMedia(constraints);
          if (RECORDER_PROVIDER === "rtc") {
            speechRef.current = new RecordRTC(audioStreamRef.current, {
              type: "audio",
              recorderType: StereoAudioRecorder,
              mimeType: "audio/wav",
              timeSlice: 480,
              desiredSampRate: 8000,
              numberOfAudioChannels: 1, // Allows for stereo recording
              ondataavailable: async (blob) => {
                const arrayBuffer = await blob.arrayBuffer();
                if (transcriptionActive && isMicrophoneOn && wsRef.current.readyState === WebSocket.OPEN) {
                  wsRef.current.send(arrayBuffer);
                }
              }
            });
          } else {
            speechRef.current = new MediaRecorder(audioStreamRef.current);
            speechRef.current.ondataavailable = (e) => {
              (async () => {
                if (transcriptionActive && isMicrophoneOn) {
                  wsRef.current.send(e.data);
                }
              })();
            };
          }
        }

        sendAudio();
      })();
    };

    const sendAudio = () => {
      if (speechRef.current) {
        if (RECORDER_PROVIDER === "rtc") {
          speechRef.current.startRecording();
        } else {
          speechRef.current.start(1000);
        }
      }
    };

    const stopSpeechRecognization = () => {
      if (audioStreamRef.current) {
        audioStreamRef.current.getAudioTracks().forEach((track) => {
          track.stop();
        });
      }
      if (speechRef.current) {
        if (RECORDER_PROVIDER === "rtc") {
          speechRef.current.stopRecording();
          speechRef.current = null;
        } else {
          speechRef.current.stop();
        }
      }
    };

    useEffect(() => {
      if (transcriptionActive && isMicrophoneOn) {
        startSpeechRecognization();
      } else {
        stopSpeechRecognization();
      }
    }, [transcriptionActive, isMicrophoneOn]);

    useEffect(() => {
      if (error) {
        onError(error);
      }
    }, [error]);

    useEffect(() => {
      selfIdentityRef.current = localParticipant?.identity;
      if (localParticipant?.identity && recipientFlow) {
        const visibility = document.hasFocus() ? !document.hidden : false;
        shareVisibilityInformation(visibility);

        document.addEventListener("visibilitychange", handlePageVisibility);
        window.addEventListener("blur", handleWindowBlur);
        window.addEventListener("focus", handleWindowFocus);
      }

      return () => {
        document.removeEventListener("visibilitychange", handlePageVisibility);
        window.removeEventListener("blur", handleWindowBlur);
        window.removeEventListener("focus", handleWindowFocus);
      };
    }, [localParticipant, recipientFlow]);

    useEffect(() => {
      if (currentConversation?.location?.updatedAt) {
        setLocationIsFresh(
          currentConversation.location?.updatedAt &&
            currentConversation.location?.updatedAt / 1000 > Math.floor(Date.now() / 1000 - 15 * 60)
        );
        if (!intervalIDRef.current) {
          intervalIDRef.current = setInterval(() => {
            setLocationIsFresh(
              currentConversation.location?.updatedAt &&
                currentConversation.location?.updatedAt / 1000 > Math.floor(Date.now() / 1000 - 15 * 60)
            );
          }, 30 * 1000);
        }

        return () => {
          if (intervalIDRef.current) {
            clearInterval(intervalIDRef.current);
          }
        };
      }
    }, [currentConversation?.location?.updatedAt]);

    if (!active) {
      return null;
    }

    if (room) {
      return (
        <>
          {!recipientFlow && (
            <SelectCameraDevice
              show={showSelectCamModal}
              identity={selectCameraIdentity}
              cameraList={selectCameraList}
              onSelect={onSelectRemoteCamera}
              onClose={() => setShowSelectCamModal(false)}
            />
          )}
          {!disconnected && (
            <VideoContainer
              transcriptionActive={transcriptionActive}
              className={`${app.videoRoomMinimized ? "minimized" : ""}`}
            >
              <div>
                <VideoWrap
                  minimized={app.videoRoomMinimized}
                  backgroundImage={
                    !remoteParticipants || remoteParticipants.length === 0 ? agency.settings.agencyLogo : ""
                  }
                >
                  {localParticipant && (
                    <Participant
                      roomId={roomId}
                      conversationId={conversationId}
                      participant={localParticipant}
                      identity={localParticipant.identity}
                      vertical={app.initOptions.options.verticalVideo}
                      recipientFlow={recipientFlow}
                      dialects={dialects}
                      enableAudio={enableAudio}
                      enableVideo={enableVideo}
                      isCameraOn={isCameraOn}
                      isMicrophoneOn={isMicrophoneOn}
                      isLocalParticipant={true}
                      numberOfParticipants={remoteParticipants.length + 1}
                      minimized={app.videoRoomMinimized}
                      showRemoteControl={false}
                      blur={false}
                      currentSpeaker={speaker}
                      localAudioTrack={localAudioTrack}
                      localVideoTrack={localVideoTrack}
                      visibility={true}
                    />
                  )}
                  {remoteParticipants.map((p, i) => (
                    <Participant
                      key={[p.identity, i].join("")}
                      roomId={roomId}
                      conversationId={conversationId}
                      recipientFlow={recipientFlow}
                      participant={p}
                      identity={p.identity}
                      vertical={app.initOptions.options.verticalVideo}
                      blur={blur}
                      enableVideo={true}
                      enableAudio={true}
                      isLocalParticipant={false}
                      defaultDialect={recipientLanguagesRef.current[p.identity] || "en"}
                      currentSpeaker={speaker}
                      onSelectLanguage={async (pIdentity, pSelectedLanguage) => {
                        ConveySocket.getInstance().socketEmit(ConveySocket.VIDEO_REMOTE_CONTROL_CMD, {
                          event: "changeRecognitionLanguage",
                          conversationId,
                          identity: pIdentity,
                          data: pSelectedLanguage.code
                        });
                      }}
                      onSwitchCamera={(pIdentity) => onOpenSelectCamDeviceModal(pIdentity)}
                      onToggleAudio={(pIdentity, status) => {
                        ConveySocket.getInstance().socketEmit(ConveySocket.VIDEO_REMOTE_CONTROL_CMD, {
                          event: status ? "enableAudio" : "disableAudio",
                          conversationId,
                          identity: pIdentity
                        });
                      }}
                      onToggleVideo={(pIdentity, status) => {
                        ConveySocket.getInstance().socketEmit(ConveySocket.VIDEO_REMOTE_CONTROL_CMD, {
                          event: status ? "enableCamera" : "disableCamera",
                          conversationId,
                          identity: pIdentity
                        });
                      }}
                      onShareLocation={(pIdentity) => {
                        ConveySocket.getInstance().socketEmit(ConveySocket.VIDEO_REMOTE_CONTROL_CMD, {
                          event: "shareLocation",
                          conversationId,
                          identity: pIdentity
                        });
                      }}
                      dialects={dialects}
                      numberOfParticipants={remoteParticipants.length + 1}
                      minimized={app.videoRoomMinimized}
                      showRemoteControl={!recipientFlow}
                      visibility={recipientFlow ? true : app.visibilityStatus[p.identity]}
                    />
                  ))}
                </VideoWrap>
              </div>
            </VideoContainer>
          )}
          {!disconnected && transcriptionActive && (
            <TranscriptionContainer id={`transcription-container-${conversationId}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}
              >
                {transcriptsRef.current.map((transcript) => {
                  return (
                    <div
                      key={transcript.id || transcript.line}
                      className={`transcript-item transcript-identity-${
                        transcript.identity !== app.agencyName
                          ? transcriptsIdentitiesRef.current.indexOf(transcript.identity)
                          : "responder"
                      }`}
                    >
                      {transcript.line}
                    </div>
                  );
                })}
                <div id="last-transcription-item" />
              </div>
            </TranscriptionContainer>
          )}
          {disconnected && (
            <VideoContainer transcriptionActive={false} className={`${app.videoRoomMinimized ? "minimized" : ""}`}>
              <div>
                <VideoWrap minimized={app.videoRoomMinimized} backgroundImage={agency.settings.agencyLogo}></VideoWrap>
              </div>
            </VideoContainer>
          )}
          {locationIsFresh && currentConversation.location && (
            <FlexCenterRow
              justify="center"
              style={{ marginTop: 10, cursor: "pointer" }}
              onClick={() => {
                setShowMap(new Date().getTime());
              }}
            >
              <UnderlinedSpan>{currentConversation.location.normalizedAddress}</UnderlinedSpan>
              {currentConversation.location.updatedAt && (
                <ItalicSpan>&nbsp;{getLastLocationTime(currentConversation.location.updatedAt)}</ItalicSpan>
              )}
              {!!showMap && (
                <LocationModal
                  $mapId={"LocationHeader"}
                  show={showMap}
                  nextNavShown={nextNavShown}
                  conversationId={conversationId}
                  onClose={() => {
                    setShowMap(0);
                  }}
                  mapLoaded={() => {
                    setNextNavShown(true);
                  }}
                  locationIsFresh={locationIsFresh}
                />
              )}
            </FlexCenterRow>
          )}
          <MediaContainer>
            {useDevices && !disconnected && (
              <DeviceSelectorContainer>
                {identity !== "Authorized Participant" && (
                  <DeviceSelector
                    icon={"mic"}
                    onSelect={(device) => {
                      const { kind, deviceId } = device;
                      if (kind === "audioinput") {
                        onSelectMicDevice(deviceId);
                      } else {
                        setSpeaker(deviceId);
                      }
                    }}
                    onToggle={() => {
                      if ([...micList, ...speakerList].length > 0) {
                        toggleMicrophone();
                      }
                    }}
                    devices={[...micList, ...speakerList]}
                    active={isMicrophoneOn && [...micList, ...speakerList].length > 0}
                    currentMicDevice={mic}
                    currentSpeakerDevice={speaker}
                  />
                )}
                {agency.settings.videoEnabled &&
                  agency.settings["video:enableAgentVideo"] &&
                  identity !== "Authorized Participant" && (
                    <DeviceSelector
                      icon={"camera"}
                      onSelect={(selectedActiveDeviceID) => onSelectCameraDevice(selectedActiveDeviceID)}
                      onToggle={() => {
                        if (cameraList.length > 0) {
                          toggleCamera();
                        }
                      }}
                      devices={cameraList}
                      active={isCameraOn && cameraList.length > 0}
                      currentCameraDevice={camera}
                    />
                  )}
                {recipientFlow && localParticipant && identity !== "Authorized Participant" && (
                  <LanguageSelector
                    currentDialect={recipientLanguagesRef.current[localParticipant.identity] || "en"}
                    dialects={dialects}
                    onSelect={(lang) => updateSelfLanguage(lang)}
                    disabled={!recipientFlow}
                  />
                )}
              </DeviceSelectorContainer>
            )}
            {recipientFlow ? (
              <>
                {!disconnected && (
                  <VideoActionButton
                    data-type="LeaveVideoRoom"
                    variant="danger"
                    onClick={() => {
                      leaveRoom();
                    }}
                  >
                    Leave Room
                  </VideoActionButton>
                )}
                {disconnected && (
                  <VideoActionButton
                    data-type="JoinVideoRoom"
                    variant="success"
                    onClick={() => {
                      joinRoom();
                    }}
                  >
                    Join Room
                  </VideoActionButton>
                )}
              </>
            ) : (
              <>
                {!app.initOptions.options.disableVideoTranscribe && (
                  <VideoActionButton
                    data-type="ToggleVideoCallTranscriptions"
                    variant={transcriptionActive ? "danger" : "default"}
                    onClick={() => {
                      ConveySocket.getInstance().socketEmit(
                        transcriptionActive
                          ? ConveySocket.REQUEST_STOP_TRANSCRIPTION_CMD
                          : ConveySocket.REQUEST_START_TRANSCRIPTION_CMD,
                        { conversationId }
                      );
                      setTranscriptionActive(!transcriptionActive);
                      setVideoRoomLastActivity(transcriptionActive ? "transcriptionInactive" : "transcriptionActive");
                    }}
                  >
                    {transcriptionActive ? "Stop Transcription" : "Start Transcription"}
                  </VideoActionButton>
                )}
                <VideoActionButton
                  data-type="EndVideoCallModal"
                  variant="danger"
                  rotate={"315deg"}
                  onClick={() => {
                    setEndLoading(true);
                    window.SDK.instances[phoneNumber]?.endVideoCall &&
                      window.SDK.instances[phoneNumber]?.endVideoCall();
                    setTimeout(() => {
                      endRoom();
                    }, 500);
                  }}
                  disabled={endLoading}
                >
                  <HangUp />
                  <span>End Call</span>
                </VideoActionButton>
              </>
            )}
          </MediaContainer>
        </>
      );
    }
  }
);

const mapStateToProps = ({ agency, conversations, app }: IRootState) => ({
  conversations,
  agency,
  app
});

export const VideoRoom = connect(
  mapStateToProps,
  {
    setVideoNoShow,
    setVideoRoomCount,
    setActiveRoomData,
    setVideoRoomLastActivity,
    setConversationLocation
  },
  null,
  {
    forwardRef: true
  }
)(XVideoRoom);
